import { Behavior } from 'backbone.marionette';
import _, { isArray } from 'underscore';
import $ from 'jquery';
import scope from '../../../scope';

import 'jquery-validation';

var grecaptcha = window.grecaptcha;
var jQuery = $;

jQuery.expr[':'].val = function (e, i, p) {
    return $(e).val() === p[3];
};

jQuery.fn.serializeArrayIncludeDisableds = function () {
    var inputs = this.find(':disabled');
    inputs.prop('disabled', false);
    var serialized = this.serializeArray.apply(this, arguments);
    inputs.prop('disabled', true);
    return serialized;
};

var Form = Behavior.extend({
    ui: {
        FormBehaviorForms: 'form:not([data-form-bypass])',
    },
    events: {},
    onAttach: function () {
        this.onRender.apply(this);
    },
    onRender: function () {
        this.onBuild.apply(this);
    },
    onBuild: function () {
        var self = this;

        this.ui.FormBehaviorForms.each(function () {
            if ($(this).data('form-listener')) {
                return;
            }

            $(this).data('form-listener', true);

            var rules = self.getOption('rules');

            if (rules) {
                for (var rule = 0; rule < rules.length; rule++) {
                    var validate = rules[rule].default ? rules[rule].default.validate : rules[rule].validate;

                    if (validate && validate.methods) {
                        validate.methods(jQuery.validator);
                    }
                }
            }

            self.formListener($(this));
        });
    },
    formListener: function (form) {
        this.placeHolderListener(form);

        $(form).on('change', 'select[data-autosubmit]', function(){
            var el = $(this);
            var target = $(el.data('autosubmit'));
            
            if(!target || target.length <= 0){
                target = el.parents('form');
            }

            target.trigger('submit');
        });

        var rules = this.getOption('rules');

        if (rules) {
            for (var rule = 0; rule < rules.length; rule++) {
                var validate = rules[rule].default ? rules[rule].default.validate : rules[rule].validate;

                if (validate && validate.listener) {
                    validate.listener(form);
                }
            }
        }

        this.rebuild(form);
    },
    placeHolderListener: function (form) {
        var placeholderFocus = function (e) {
            var placeholder = $(this).parents('.placelabel');
            var hasFilePound = placeholder.find('.filepond--root');

            if (hasFilePound.length > 0) {
                return;
            }

            if (placeholder.length) {
                placeholder.addClass('ontop');
            }
        };

        var placeholderBlur = function (e) {
            var placeholder = $(this).parents('.placelabel');
            var reveal = $(this).closest('[data-reveal-listener]');
            var hasFilePound = placeholder.find('.filepond--root');
            var originalval = $(this).val();

            if($(reveal).css('display') === 'none'){
                return;
            }

            if($(this).is('[type=radio]')) {
                originalval = $('[name='+ $(this).attr('name') +']:checked').val() || '';
            }

            var hiddenval = $($(this).data('hidden-value'));

            if (hasFilePound.length > 0) {
                return;
            }

            if (hiddenval.length > 0) {
                originalval = hiddenval.val();
            }

            console.log(placeholder.length, originalval);
            if (placeholder.length > 0 && originalval == '') {
                placeholder.removeClass('ontop');
            } else {
                placeholder.addClass('ontop');
            }
        };

        $(form).on('focus', '.placelabel input, .placelabel textarea, .placelabel  select, .placelabel .custom-multiselect', placeholderFocus);

        $(form).on('blur change ontop', '.placelabel input, .placelabel textarea, .placelabel  select, .placelabel .custom-multiselect', placeholderBlur);

        $('body').on('focus', '.select2-container', function(e){
            if($(e.currentTarget).data('select'))
                placeholderFocus.apply($(e.currentTarget).data('select'), [e]);
        });

        $('body').on('blur', '.select2-container', function(e){
            if($(e.currentTarget).data('select'))
                placeholderBlur.apply($(e.currentTarget).data('select'), [e]);
        });

        $(form).on('reset', function(){
            var frm = this;

            setTimeout(function(){
                var validator = form.validate();

                $('.placelabel input, .placelabel textarea, .placelabel select, .placelabel .custom-multiselect', frm).each(function () {
                    if($(this).is('input[type="text"],textarea')){
                        $(this).val($(this).attr('value') !== '' ? $(this).attr('value') : '').trigger('change');
                    } else {
                        $(this).val('').trigger('change');
                        $(this).parents('.placelabel').removeClass('ontop');
                    }
                });

                $('[name]', frm).each(function(){
                    $(this).removeClass('error').parents('label,.fieldlabel').removeClass('error');
                    validator.successList.push(this);
                    validator.showErrors();
                });

                $('.formerrors', frm).hide().empty();

                validator.resetForm();
            }, 50)

            console.log('reseted', arguments, this);
        });

        $('.placelabel input, .placelabel textarea, .placelabel  select, .placelabel .custom-multiselect', form).each(function () {
            console.log('forcar', $(this).attr('name'), $(this).val());
            placeholderBlur.apply(this, []);

            var inputEl = this; // select the el with any way, here is ReactJs ref
            var hasValue = false;

            setTimeout(function(){
                try {
                    hasValue = inputEl.matches(':autofill');
                } catch (err) {
                    try {
                        hasValue = inputEl.matches(':-webkit-autofill');
                    } catch (er) {
                        hasValue = false;
                    }
                }

                if(hasValue){
                    var placeholder = $(inputEl).parents('.placelabel');
                    var hasFilePound = placeholder.find('.filepond--root');

                    if (hasFilePound.length > 0) {
                        return;
                    }

                    if (placeholder.length) {
                        placeholder.addClass('ontop');
                    }
                }
            }, 600);
        });
        
    },
    onFormRebuild: function (form) {
        this.rebuild(form);
    },
    rebuild: function (form) {
        var self = this;

        form.validate({
            ignore: '.ignore,.ignore-empty:val(""),:hidden:not(.no-ignore,.g-recaptcha-response),[readonly]',
            errorElement: 'span',
            invalidHandler: function (error, validator) {
                if (validator.errorList.length > 0) {
                    return false;
                }
            },
            errorPlacement: function (error, element) {
                if (element.attr('name') == 'g-recaptcha-response') {
                    error.appendTo(element.closest('.formcaptcha'));
                } else if (element.attr('data-field-holder')) {
                    error.appendTo($(element.attr('data-field-holder')));
                } else if (element.parents('[data-field-holder]').length) {
                    error.appendTo(element.parents('[data-field-holder]').eq(0));
                } else if (element.closest('.fieldlabel').length) {
                    error.appendTo(element.closest('.fieldlabel'));
                } else if (element.closest('label').length) {
                    error.appendTo(element.closest('label'));
                } else {
                    error.insertAfter(element);
                }
            },
            highlight: function (element, errorClass, validClass) {
                $(element).removeClass(validClass).addClass(errorClass);
                $(element).parents('.fields, [data-field-holder], .fieldlabel, label').removeClass(validClass).addClass(errorClass);

                var errs = this.errorList;

                $.each(errs, function (key, value) {
                    if(errs[key].element === element){
                        console.log('adiciona erro', errs[key].element.name, errs[key].method);
                        $(element).data('errorinfo', errs[key].method);
                    }
                });

                this.settings.groupHighlight(this, element, errorClass, validClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(errorClass).addClass(validClass);
                $(element).parents('.fields, [data-field-holder], .fieldlabel, label').removeClass(errorClass).addClass(validClass);

                // console.log('remove erros', element.name);
                $(element).data('errorinfo', false);

                this.settings.groupUnhighlight(this, element, errorClass, validClass);
            },
            groupHighlight: function(validator){
                validator.elements().filter('.error').each(function(){
                    var error = $(this).data('errorinfo');
                    var group = $(this).parents('[data-group-holder]');

                    if(error && group && group.length > 0){
                        group.addClass('error_group_' + error);
                    }
                });
            },
            groupUnhighlight: function(validator){
                validator.elements().filter('.valid').each(function(){
                    var group = $(this).parents('[data-group-holder]');

                    if(group && group.length > 0){
                        group.removeClass(function (index, css) {
                            return (css.match(/\berror_group_\S+/g) || []).join(' '); 
                       });
                    }
                });
            },
            submitHandler: function (frm, e) {
                // e.preventDefault();

                
                var executeOnSumitHandler = function () {
                    if (form.data('prevent-submit')) {
                        return;
                    }

                    form.data('prevent-submit', true);
                    $('[type=submit],[data-prevent-double-click]').prop('disabled', true);

                    if (typeof form.attr('data-ajax-bypass') !== 'undefined') {
                        form[0].submit();

                        $('[type=submit],[data-prevent-double-click]').prop('disabled', false);
                        form.data('prevent-submit', false);

                        return true;
                    }

                    var serializeData = self.formSerialize(form);
                    var serializedFormData = new FormData();

                    form.find('input[type=file]').each(function () {
                        if (this.files && this.files[0]) {
                            serializedFormData.append($(this).attr('name'), this.files[0]);
                        }
                    });
                    form.find('[data-form-file]').each(function () {
                        serializedFormData.append($(this).attr('data-form-file'), $(this).data('file'));
                    });

                    var action = form.attr('action');
                    var method = form.attr('method');

                    if (method === 'POST') {
                        if (form.data('set-formdata')) {
                            $(serializeData).each(function () {
                                serializedFormData.set(this.name, this.value);
                            });
                        } else {
                            $(serializeData).each(function () {
                                serializedFormData.append(this.name, this.value);
                            });
                        }
                    } else {
                        serializedFormData = {};

                        if(form.data('pushstate')){
                            serializedFormData.pushstate = "true";
                        }

                        $(serializeData).each(function () {
                            serializedFormData[this.name] = (!serializedFormData[this.name] || serializedFormData[this.name] === this.value) ? this.value : serializedFormData[this.name] + ',' + this.value;
                        });
                    }

                    var successProccess = function (response, xhr) {
                        console.log('form:validate:response:call:success', response, xhr);
                        if (response.error && response.message) {
                            response.success = false;
                            response.error = {
                                code: response.error,
                                message: response.message,
                            };
                        } else if (response.errors && response.errors[0]) {
                            response.success = false;
                            response.error = response.errors[0];
                        } else if (response.message) {
                            form.find('[data-message]').text(response.message);
                        } else {
                            response.success = true;
                        }

                        var autoupdateurl = form.data('autoupdateurl');
                        var autoupdatehash = form.data('autoupdatehash');
                        var successRedirect = form.data('success-redirect');
                        var successReload = form.data('success-reload');
                        var successNextForm = form.data('success-next-form') || '';

                        if (response.success) {
                            console.log('form:validate:response:trigger:success', response, xhr);
                            form.trigger('submitSuccess', [response, xhr]);

                            if(response.reset){
                                form.trigger('reset');
                            }

                            if (autoupdateurl) {
                                scope.app.navigate(xhr.url.replace(/(pushstate|_token)(=[^&]*)?&?/gim, ''), false, autoupdatehash);
                            }

                            if (successReload) {
                                window.location = successReload;
                            } else if (successRedirect) {
                                scope.app.navigate(successRedirect, true);
                            } else if (response.sections) {
                                scope.app.setup(window.location.url, response);
                            } else if (response.external_redirect) {
                                window.location.href = response.external_redirect;
                            } else if (response.redirect) {
                                scope.app.navigate(response.redirect, false);
                            } else if (successNextForm.search('#form-') != -1) {
                                var successNextFormElement = $(successNextForm);

                                form.hide();
                                self.formFeed(successNextFormElement, response.data);
                                successNextFormElement.data('parent', form);
                                successNextFormElement.show();
                            }
                        } else {
                            console.log('form:validate:response:trigger:error', response, xhr);
                            form.trigger('submitError', [response, xhr]);

                            var errorRedirect = form.data('error-redirect');

                            if (errorRedirect) {
                                scope.app.navigate(errorRedirect, true);
                                return;
                            }

                            self.showError(form, response.error.message || '');
                        }

                        $('[type=submit],[data-prevent-double-click]').prop('disabled', false);
                        form.data('prevent-submit', false);
                    };

                    var errorProccess = function (err, xhr) {
                        console.log('form:validate:response:call:error', err, xhr);
                        $('[type=submit],[data-prevent-double-click]').prop('disabled', false);
                        form.data('prevent-submit', false);

                        console.log('form:validate:response:trigger:error', err, xhr);
                        form.trigger('submitError', [err, xhr]);

                        if (!err.success) {
                            self.showError(form, err || 'Erro interno do servidor.');
                        }
                    };

                    var options = {
                        async: true,
                        crossDomain: true,
                        pushstate: true,
                        noscroll: true,
                        contentType: method === 'POST' ? false : 'application/x-www-form-urlencoded; charset=UTF-8', // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
                        processData: method !== 'POST', // NEEDED, DON'T OMIT THIS
                        url: form.attr('action'),
                        method: form.attr('method'),
                        mimeType: form.attr('mimeType'),
                        dataType: 'json',
                        data: serializedFormData,
                        success: function (response, status, xhr) {
                            setTimeout(function(){
                                if(!_.isObject(response) && xhr.redirected) {
                                    form.trigger('submitSuccess', [response, xhr]);
                                    return;
                                }
                                
                                successProccess(response, xhr);
                            }, 100);
                        },
                        error: function (xhr) {
                            setTimeout(function(){
                                var response = xhr.responseJSON ? xhr.responseJSON : '';

                                if(!_.isObject(response) && xhr.redirected) {
                                    form.trigger('submitSuccess', [response, xhr]);
                                    return;
                                }

                                errorProccess(response, xhr);
                            }, 100);
                        },
                        complete: function () {
                            if (form.find('.render-captcha').length) {
                                window.grecaptcha.reset(form.find('.render-captcha').data('captcha-id'));
                            }
                        },
                    };

                    form.trigger('beforeProcessRequest', [options]);

                    if (action) {
                        $.ajax(options);
                    } else {
                        form.trigger('processRequest', [serializedFormData, successProccess, errorProccess]);
                    }
                };
                try{
                    if (form.find('.render-captcha').length) {
                        var promise = form.find('.render-captcha').data('captcha-promise');

                        if (promise) {
                            promise.progress(executeOnSumitHandler);
                            form.find('.render-captcha').data('captcha-promise', false);
                        }

                        window.grecaptcha.execute(form.find('.render-captcha').data('captcha-id'));
                    } else {
                        executeOnSumitHandler();
                    }

                }catch(er){
                    e && e.preventDefault && e.preventDefault();
                    executeOnSumitHandler();
                    console.error(er);
                }

                return false;
            },
        });
    },
    formFeed: function (form, data) {
        for (var key in data) {
            form.find('[name="' + key + '"]').each(function () {
                $(this).val(data[key]);
            });
        }
    },
    formSerialize: function (form) {
        var self = this;
        var rules = this.getOption('rules');
        var selectors = [];

        if (rules) {
            for (var rule = 0; rule < rules.length; rule++) {
                var validate = rules[rule].default ? rules[rule].default.validate : rules[rule].validate;

                if (validate && validate.cleanOnSerialize) {
                    selectors = selectors.concat(validate.cleanOnSerialize);
                }
            }
        }

        var serialize = [];
        var serializeArray = form.data('readonly-mode') ? form.serializeArrayIncludeDisableds() : form.serializeArray();

        serializeArray.map(function (value, i) {
            var test = form.find('[name="' + value.name + '"]');

            if (value.name.search(/\[\]/gim) !== -1) {
                test = test.filter('[value="' + value.value + '"]');
            }

            test = test.filter('.ignore-field,.ignore-empty-payload:val(""),.ignore-empty:val(""),:hidden:not(.no-ignore,.g-recaptcha-response)').not('[type=hidden]');

            if (test.length > 0) {
                return null;
            }

            serialize.push(value);
        });

        var fields = form.find([selectors].join(','));

        fields.each(function () {
            var input = $(this);
            var name = input.attr('name');
            var value = input.maskClear ? input.maskClear() : self.clearInputValue(input);

            self.changeSerializeValue(serialize, name, value);
        });

        return serialize;
    },
    changeSerializeValue: function (serialize, name, value) {
        $(serialize).each(function () {
            if (this.name === name) {
                this.value = value;
            }
        });
    },
    clearInputValue: function (input) {
        return (input.val() || '').replace(/[^0-9]/g, '');
    },
    parseLaravelFieldKey: function (key) {
        var fieldNameArray = key.split('.'),
            fieldName = fieldNameArray[0];

        for (var i = 1; i < fieldNameArray.length; ++i) {
            fieldName += '[' + fieldNameArray[i] + ']';
        }

        return fieldName;
    },
    showError: function (form, message) {
        var self = this;
        var typeError = form.data('error');
        var parentForm = form.data('parent');

        if (parentForm) {
            form.hide();
            parentForm.show();
        }

        if (message) {
            if (message.errors) {
                try {
                    var errors = {};

                    $.each(message.errors, function (key, value) {
                        errors[self.parseLaravelFieldKey(key)] = value;
                    });
                    message.errors = errors;
                    form.validate().showErrors(message.errors);

                    return;
                } catch (e) {
                    message = false;
                }
            } else if (message.success === false || message.exception || message.message) {
                if (!!message.message) {
                    message = message.message;
                } else {
                    message = false;
                }
            }
            var msg = message && message !== '' ? message : 'Erro interno no servidor.';

            form.find('.formerrors').html(msg).show();
        } else {
            form.find('.formerrors').text('').hide();
        }
    },
});

$.extend($.validator.messages, {
    date: 'Digite uma data válida.',
    digits: 'Este campo só aceita dígitos',
    email: 'Informe um email válido.',
    equalTo: 'Os valores precisam ser iguais.',
    number: 'Este campo só aceita números.',
    remote: 'Campo inválido.',
    required: 'Campo obrigatório.',
    url: 'O endereço do site deve iniciar com http:// ou https://.',
    maxlength: $.validator.format('Campo deve conter no máximo {0} caracteres.'),
    minlength: $.validator.format('Campo deve conter no mínimo {0} caracteres.'),
    rangelength: $.validator.format('Campo deve conter de {0} até {1} caracteres.'),
    range: $.validator.format('Apenas numeros de {0} até {1}.'),
    max: $.validator.format('Apenas números até {0}.'),
    min: $.validator.format('Apenas números a partir de {0}.'),
    step: $.validator.format('Numeros multiplos de {0}.'),
    'money-max': $.validator.format('O valor deve ser até R$ {0}.'),
    'money-min': $.validator.format('O valor deve ser a partir de R$ {0}.'),
    branch: $.validator.format('Campo deve conter no mínimo {0} digitos.'),
});

jQuery.validator.methodGroup = function (name, rules, message) {
    message = message || 'Campo com erro';

    var getMessage = function () {
        return message;
    };

    jQuery.validator.addMethod(
        name,
        function (value, element) {
            for (var x = 0; x < rules.length; x++) {
                var rule = rules[x];
                var check = jQuery.validator.methods[rule.rule];

                if (check && !check.apply(this, [value, element, rule.param])) {
                    message = jQuery.validator.messages[rule.rule];
                    message = $.isFunction(message) ? message.apply(this, rule.param) : message;

                    return false;
                }
            }

            return true;
        },
        getMessage,
    );
};

export default Form;
