import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

import Form from './Validate/Constructor';
import FormMasks from './Masks/Constructor';

export default function(options) {
	return {
		Form: {
			behaviorClass: Form,
			rules: options.rules,
		},
		FormMasks: {
			behaviorClass: FormMasks,
			rules: options.rules,
		},
	}
};
