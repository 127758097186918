import $ from 'jquery';
import Mn from 'backbone.marionette';

function Generator() {};

Generator.prototype.rand =  Math.floor(Math.random() * 26) + Date.now();

Generator.prototype.getId = function(prefix) {
    return  prefix + this.rand++;
};
var promise = $.Deferred(), resolved = false;

window.onloadCallbackCaptcha = function () {
    console.log('LOAD CAPTCHA');
    promise.resolve();
    resolved = true;
}
var idGen =new Generator();
var Behavior = Mn.Behavior.extend({
    onAttach: function (view) {
        console.log("RENDER CAPTCHA");
        if (resolved || (window.grecaptcha  && window.grecaptcha.render) ) {
            this.build(this.$el);
        } else {
            promise.then(function () {
                this.build(this.$el);
            }.bind(this))
        }
    },
    build: function (content) {
        var self = this;
        content.find('.render-captcha').each(function () {
            var el = this;
            setTimeout(function(){
                self.renderCaptcha(el)
            },500)
        });
    },
    renderCaptcha: function(el){
        var id = idGen.getId('captcha_'), el = $(el);

        if(el.data('captcha')) {
            return;
        }

        el.data('captcha',true);

        el.attr('id', id);
        var p =  $.Deferred();
        var cId = grecaptcha.render(
            id,
            {
                sitekey: window.captchaKey,
                size: 'invisible',
                callback: function (token) {
                    p.notify(token);
                    $(id).addClass('render-captcha');
                }
            }
        );
        el.data('captcha-id', cId);
        el.data('captcha-promise', p);

        $(id).data('captcha-id', cId);
        $(id).data('captcha-promise', p);
        $(id).addClass('render-captcha');
    }
});

export default Behavior;