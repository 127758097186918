import Component from '../../libs/component';
import scope from '../../scope';
import Form from '../../behaviors/Form';
import FormCommons from '../../behaviors/Form/Commons';
import FormLogin from '../../behaviors/Form/Login';
import FormMedias from '../../behaviors/Form/Medias';
import Requireone from '../../behaviors/Form/Requireone';
import DatePicker from '../../behaviors/Form/Validate/DatePicker';
import Requireif from '../../behaviors/Form/Requireif';
import Select2 from '../../behaviors/Select2';
import FormSelectPopulate from '../../behaviors/FormSelectPopulate';
import FormParamUpdate from '../../behaviors/FormParamUpdate';
import FormCEP from '../../behaviors/FormCEP';
import Captcha from '../../behaviors/Captcha';
import $ from 'jquery';
import ranger from 'rangeslider.js';
import cookies from 'js-cookie';


scope.components['form'] = Component.extend({
    name: 'form',
    ui: {
        'form': 'form',
        'range': 'input[type="range"]',
        'reveal': '[data-reveal]',
        'pet': '[data-preferpet]'
    },
    events:{
        'change @ui.pet': 'setPreferPet'
    },
    behaviors: {
        'Captcha': Captcha,
        ...Form({
            rules: [FormCommons, FormLogin, FormMedias, Requireone, Requireif, DatePicker],
        }),
        'Select2': Select2,
        'FormSelectPopulate': FormSelectPopulate,
        'FormParamUpdate': FormParamUpdate,
        'FormCEP': FormCEP
    },
    onAttach: function(){
        var self = this;

        self.ui.range.rangeslider({
            polyfill: false,
        });

        self.ui.form.on('submitSuccess', self.bindTrigger('form:success'))
        self.ui.form.on('submitError', self.bindTrigger('form:error'))
        self.ui.form.on('beforeProcessRequest', self.bindTrigger('form:before:request'))
        self.ui.form.on('processRequest', self.bindTrigger('form:after:request'))

        console.log('form:attach');

        
        self.ui.reveal.one('reveal', function(e, name, value, target){
            target.addClass('reveal-ranged');
            setTimeout(function(){
                $('input[type="range"]').each(function(){
                    var e = $(this); 
                    var v = e.attr('value'); 
                    
                    e.val(e.attr('min')).change();

                    setTimeout(function(){ 
                        e.val(v).change();

                        target.removeClass('reveal-ranged');
                    }, 1);
                });
            },750)
        });

        var preferpet = cookies.get('preferpet');

        //!this.ui.pet.filter(':checked').length
        if(preferpet){
            this.ui.pet.filter('[value="'+preferpet+'"]').prop('checked', 'checked');
        }
    },
    setPreferPet: function(e){
        var val = $(e.currentTarget).val();

        console.log("cookie:pet", val);
        cookies.set('preferpet', val);
    },
    onDestroy: function(){
    },
});

export default scope.components['form'];
