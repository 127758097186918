
import mask from './Masks/Login';
import validate from './Validate/Login';

export default {
    mask: mask.mask,
    validate: {
        cleanOnSerialize: Object.values(mask.mask.ui), 
        ...validate.validate,
    }
};
