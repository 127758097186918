import Layout from '../../libs/layout';
import scope from '../../scope';
import Carousel from '../../behaviors/Carousel';
import Like from '../../behaviors/Like';
import Header from '../components/header';
import Form from '../components/form';

scope.layouts.Default = Layout.extend({
    behaviors: {
        Carousel: Carousel,
        Like: Like,
    },
    ui: {
    },
    events: {
    },
});

export default scope.layouts.Default;

