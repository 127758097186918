import { Behavior } from 'backbone.marionette';
import $ from 'jquery';

var FormCEP = Behavior.extend({
    ui: {
        formCEP: '[data-cep]',
        formCEPTargets: '[data-cep-address],[data-cep-number],[data-cep-complement],[data-cep-neighborhood],[data-cep-state],[data-cep-city]',
        formCEPAddress: '[data-cep-address]',
        formCEPNumber: '[data-cep-number]',
        formCEPComplement: '[data-cep-complement]',
        formCEPNeighborhood: '[data-cep-neighborhood]',
        formCEPState: '[data-cep-state]',
        formCEPCity: '[data-cep-city]',
    },
    events: {
        'change @ui.formCEP': 'getInfos',
    },
    onRender: function () {
        this.onAttach();
    },
    onViewAttach: function(){
        this.onAttach();
    },
    onComponentChange: function(){
        this.onAttach();
    },
    onAttach: function () {
        var self = this;

        console.log('cep:attach');
    },
    getInfos: function(e) {
        var self = this;
        var val = self.ui.formCEP.val();

        if(!/^[0-9]{5}-[0-9]{3}$/.test(val)){
            return;
        }

        self.ui.formCEPTargets.attr('disabled', 'disabled');

        $.ajax({
            url: 'https://viacep.com.br/ws/'+ val + '/json/',
            dataType: 'jsonp',
        }).done(function(data){
            if (!("erro" in data)) {
                self.ui.formCEPAddress.val(data.logradouro).trigger('change');
                self.ui.formCEPNumber.val('').trigger('change');
                self.ui.formCEPNeighborhood.val(data.bairro).trigger('change');
                self.ui.formCEPCity.one('populate', function(){
                    $(this).val(data.ibge).trigger('change');
                });
                self.ui.formCEPState.val(Number(String(data.ibge).slice(0, 2))).trigger('change');
            }

            self.ui.formCEPTargets.removeAttr('disabled');
        }).fail(function(){
            self.ui.formCEPTargets.removeAttr('disabled');
        })
    }
});

export default FormCEP;
