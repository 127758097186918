import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';
import helpers from '../../../libs/helpers';

import 'jquery-mask-plugin';

var masks = {
};

export default {
    mask: {
        ui: {
            'url2': '.mask_url2, [data-rule-url2]',
            'facebook-url': '.mask_facebook, [data-rule-facebook-url]',
            'instagram-url': '.mask_instagram, [data-rule-instagram-url]',
            'twitter-url': '.mask_twitter, [data-rule-twitter-url]',
            'linkedin-url': '.mask_linkedin, [data-rule-linkedin-url]',
            'youtube-url': '.mask_youtube, [data-rule-youtube-url]',
        },
        listener: function(form) {
            var addHttp = function(){
                var url = $(this).val();

                if(url && url !== '' && url.search('http') === -1){
                    if(url.search('www') === -1){
                        url = 'www.' + url;
                    }

                    url = 'https://' + url;
                }

                return url;
            };

            $(this.ui['url2'], form).data('maskClearFn', addHttp);
            $(this.ui['facebook-url'], form).data('maskClearFn', addHttp);
            $(this.ui['instagram-url'], form).data('maskClearFn', addHttp);
            $(this.ui['twitter-url'], form).data('maskClearFn', addHttp);
            $(this.ui['linkedin-url'], form).data('maskClearFn', addHttp);

            $(this.ui['youtube-url'], form).data('maskClearFn', function(){
                var url = $(this).val();
                console.log('clear youtube url', url);
                
                var result = helpers.getParameterByName('v', url);

                if(!result || result === ''){
                    result = url ? url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/) : undefined;
                    result = result ? result[2] : undefined;
                }

                return result ? result.split(/[^0-9a-z_\-]/i)[0] : url;
            });
        }
    }
};
