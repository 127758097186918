
import mask from './Masks/Commons';
import validate from './Validate/Commons';

export default {
    mask: mask.mask,
    validate: {
        // cleanOnSerialize: Object.values(mask.mask.ui), 
        ...validate.validate
    },
};
