import Backbone from 'backbone';
import $ from 'jquery';
import Base from './view';
// import metrics from './metrics';
import scope from '../scope';

var first = true; 
var Layout = Base.extend({
    name: 'layout',
    events: {},
    behaviors: {},
    components: {
        content: {
            region: '#content'
        }
    },
    ui: {},
    initialize: function () {
        var self = this;
        var last = null;

        self.getRegion('content').on('show', function (layout, view) {
            self.triggerMethod('viewAttach', view);

            $('body')
                .removeClass(last)
                .addClass('loaded')
                .addClass((last = 'route-' + view.name));
            $('#main')
                .removeClass(last)
                .addClass((last = 'route-' + view.name));

            setTimeout(function () {
                first = false;
                // metrics.initSections();
                $('body').removeClass('page-changed');
            }, 500);
        });
    },
    waiting: function(status, delay){

        clearTimeout(this.waitingDelay);

        this.waitingDelay = setTimeout(function(){
            if(status){
                $('body').addClass('waiting');
            }else{
                $('body').removeClass('waiting');
            }
        }, delay || 0);
    },
    go: function (view, cb) {
        var self = this, load;

        // scope.metrics.stopScroll();
        
        if(!self.components.content.instance){
            self.components.content.instance = view;
            load = self.load();
        }else{
            load = view.load();
        }

        load.done(function (response) {
            if (response.error) {
                return Backbone.history.navigate('error', { trigger: true });
            }

            if(!scope.app.started) {
                self.populate();
                scope.app.started = true;
            }else{
                view.populate();
                self.getComponent('content').show(view);
            }

            // scope.metrics.resumeScroll();
            cb && cb(view);
        });

        load.catch(function (e) {
            cb && cb(view);
        })
    },
});

export default Layout;