import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

$.fn.maskClear = function(){
    var fn = $(this).data('maskClearFn');
    return fn ? fn.apply(this) : ($(this).val() || '').replace($(this).data('maskClearRegex') || /^$/, '');
};

var FormMask = Behavior.extend({
    ui: {
        FormMasksForm: 'form:not([data-form-bypass])',
    },
    events: {
    },
    onRender: function () {
        this.onAttach();
    },
    onAttach: function () {
        var self = this;

        this.ui.FormMasksForm.each(function () {
            if ($(this).data('form-mask-listener')) {
                return;  
            }

            $(this).data('form-mask-listener', true);    
            self.formListener($(this));
        });
    },
    formListener: function( form ) {
        var rules = this.getOption('rules');

        if (rules) {
            for(var rule = 0; rule < rules.length; rule++) {
                var mask = rules[rule].default ? rules[rule].default.mask : rules[rule].mask;
                
                if (mask && mask.listener) {
                    mask.listener( form );
                }
            }
        }
    }
});


export default FormMask;
