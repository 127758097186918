import $ from 'jquery';
import scope from './scope';
import app from './app';

window.windowOpen = window.open;
window.currentOpen = null;

window.open = function(){
    return window.currentOpen = window.windowOpen.apply(this, arguments);
}

scope.app = app;

if(!window.__vite_is_modern_browser){
    $('html').addClass('nomodule');
}

scope.gsi = function(cb){
    var fn;

    if(window.google){
        while (fn = scope.gsi.stack.pop()){
            fn(window.google.accounts.id);
        }

        if(cb){
            cb(window.google.accounts.id);
        }
    }else{
        scope.gsi.stack.push(cb)
    }
}
scope.gsi.stack = [];

$(document).ready(function () {
    console.log('jquery:ready');
    Window.jQuery = Window.$ = window.jQuery = window.$ = $

    scope.app.start();
});

window.onGoogleLibraryLoad = function(){
    scope.gsi();
}
