import $       from 'jquery';
import Mn      from 'backbone.marionette';
import _      from 'underscore';
import scope from './scope';
import Layout  from './views/layout/default';
import Router  from './libs/router';
import helpers from './libs/helpers';
import loadScript from 'simple-load-script';

import.meta.glob([
    '../../images/**',
]);


var App = Mn.Application.extend({
        jquery: $,
        initialized: false,
        initialize: function () {
            console.log("app:initialize");
            this.body = $('body');
            this.base = $('#base-id').attr('href') || window.location.href;
        },
        onStart: function () {
            console.log("app:start");
            var self = this;
            this.layout = new Layout({el: document.body});
            this.layout.triggerMethod('attach', this.layout);

            this.router = new Router(this);

            this.setup(window.location.href, {}, function(){
                console.log("app:setup:complete");
                self.initialized = true;
            }, true);
        },
        caches: {},
        cache: function(url, response){
            if(response && this.caches && !this.caches[url]){
                this.caches[url] = response;
                return false;
            }

            if(this.caches[url]){
                this.setup(url, this.caches[url]);
                return true;
            }
        },
        setup: function (url, data, callback, firstRender) {
            var self = this;

            console.log("app:setup", url, data);
            console.group('setup', url);

            if(_.isString(data)){
                var html;

                try{
                    html = $(data);
                }catch(e){}

                if(html){
                    data = {
                        sections: {
                            scripts: html.filter('[data-scripts]')[0].outerHTML,
                            styles: html.filter('[data-styles]')[0].outerHTML,
                            content: html.filter('[data-view]')[0].outerHTML
                        }
                    };
                }else{
                    return;
                }
            }

            self.data = data;
            self.updateScripts(data.sections && data.sections.scripts, function(){
                self.updateStyles(data.sections && data.sections.styles, function(){
                    self.updateView(data.sections && data.sections.content, function(view, noscroll){
                        if(view.cache){
                            self.cache(url, _.extend({
                                hascache: true,
                            }, firstRender ? {
                                sections: {
                                    scripts: $('[data-scripts]')[0].outerHTML,
                                    styles: $('[data-styles]')[0].outerHTML,
                                    content: $('[data-view]')[0].outerHTML
                                }
                            } : data));
                        }
                        
                        if(data.sections && !data.hascache){
                            self.updateSessions(data.sections || {});
                        }

                        if(callback) {
                            callback.apply(this, arguments);
                        }
        
                        console.log('app:setup:noscroll', noscroll, data.noscroll);
                        if((!noscroll || data.scroll) && !data.noscroll){
                            setTimeout(function(){
                                console.log('scrollto', data.scroll || window.location.hash || 0);
                                helpers.scrollTo(data.scroll || window.location.hash || 0, window.location.hash ? 1000 : false);
                            }, 250)
                        }
                    }, firstRender);
                }, firstRender);
            }, firstRender);

            console.groupEnd();
        },
        loadScript: loadScript,
        updateScripts: function (container, callback, firstRender) {
            if(!firstRender && !container){
                callback && callback();
            }

            var self = this,
                scripts = firstRender ? $('html') : $('<div>' + container + '</div>'),
                difference = helpers.assets_difference(scripts.find('[data-scripts] script:not([nomodule])'), $('script[src]:not([nomodule])'), 'src');

            if (difference.length) {
                var promise = difference.reduce(function(previousPromise, el) {
                    return previousPromise.then(function() {
                        if(!window.__vite_is_modern_browser) {
                            return System.import($(el).next().attr('data-src'));
                        }else{
                            return self.loadScript({url: helpers.link(el.src), insertInto: "[data-scripts]", attrs: {
                                type: "module"
                            }});
                        }

                    });
                }, Promise.resolve());

                promise.then(function() {
                    callback && callback();
                })
            } else {
                callback && callback();
            }
        },
        loadStyle: function(options) {
            return new Promise(function(resolve, reject) {
                var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    link.onload = function(){ resolve() };
                    link.onerror = function(){ reject() };
                    link.href = options.url;
            
                $(link).appendTo($(options.insertInto));
            });
        },
        updateStyles: function (container, callback, firstRender) {
            if(!firstRender && !container){
                callback && callback();
            }

            var self = this,
                styles = firstRender ? $('html') : $('<div>' + container + '</div>'),
                difference = helpers.assets_difference(styles.find('[data-styles] link'), $('link[href]'), 'href');

            if (difference.length) {
                var promise = difference.reduce(function(previousPromise, el) {
                    return previousPromise.then(function() {
                        return self.loadStyle({url: helpers.link(el.href), insertInto: "[data-styles]"});
                    });
                }, Promise.resolve());

                promise.then(function() {
                    callback && callback();
                })
            } else {
                callback && callback();
            }
        },
        updateView: function(container, callback, firstRender){
            var self = this;
            var rendered = $('html').find('[data-view]');
            var view = firstRender ? rendered : $('<div>' + container + '</div>').find('[data-view]');
            var name = view.attr('data-view');
            var last = rendered.attr('data-view');
            var viewClass, viewInstance;

            if (view.length) {
                if(!firstRender && last === name){
                    var instance = rendered.data('instance');
                    console.log('app:updateview:samepage', name);

                    if(!helpers.html_difference(view, instance.html)){
                        //nao existem diferencas sem incluir os filhos!
                        console.log('app:updateview:samepage', name, 'no diferences');
                        self.updateComponents(view, rendered);
                    }else{
                        //existem diferencas sem incluir os filhos!
                        console.log('app:updateview:samepage', name, 'has diferences');
                        console.log('need update all content page', name);

                        viewClass = scope.views[name];
                        viewInstance = new viewClass({
                            el: view.children()
                        });

                        self.layout.go(viewInstance, callback);
                        return;
                    }

                    callback && callback(instance, true);
                }else{
                    helpers.keepTrying(function(){
                        viewClass = scope.views[name];
                        viewInstance = new viewClass({
                            el: view.children()
                        });

                        rendered.attr('data-view', name);
                    }, function(success){
                        if(success){
                            self.layout.go(viewInstance, callback);
                        }else{
                            return window.location.reload();
                        }
                    }, 5);
                }
            }
        },
        updateModal: function(container, callback, firstRender) {
            var self = this;
            var rendered = $('html').find('[data-modal]');
            var modal = firstRender ? rendered : $('<div>' + container + '</div>').find('[data-modal]');
            var name = modal.attr('data-modal');
            var last = rendered.attr('data-modal');
            
            if (modal.length) {
                if(!firstRender && last === name){
                    var instance = rendered.data('instance');

                    if(!helpers.html_difference(modal, instance.html)){
                        self.updateComponents(modal, rendered);
                    }

                    callback && callback(instance, true);
                }else{
                    var modalClass, modalInstance;

                    helpers.keepTrying(function(){
                        modalClass = scope.modals[name];
                        modalInstance = new modalClass({
                            el: modal //alterado para nao ser filhos
                        });

                        rendered.attr('data-modal', name);
                    }, function(success){
                        if(success){
                            callback && callback(modalInstance, true);
                        }else{
                            callback && callback();
                        }
                    }, 5);
                }
            }
        },
        updateComponents: function(container, rendered){
            var self = this;
            var components = helpers.get_components(container);

            if(components){
                console.log('app:updatecomponents', components);
                components.each(function(){
                    self.updateComponent(this, rendered, function(component){
                        self.updateComponents(component, rendered);
                    });
                });
            }
        },
        updateComponent: function(element, rendered, recursive){
            var component = $(element);
            var name = component.attr('data-component');
            var id = component.attr('id');
            var uid = component.attr('data-component-uid');
            var current = rendered.find('[data-component="' + name + '"]');
            var list = current.parents('[data-component-list]');
            var preserve = component.attr('data-component-list-mode') === 'preserve';
            var haslist = list.length > 0;

            console.log('app:updatecomponent:', component, uid || id);

            if(uid){
                current = current.filter('[data-component-uid="' + uid + '"]');
            } else if(id){
                current = current.filter('[id="' + id + '"]');
            }

            if(uid || !haslist){
                if(current.length > 0){
                    var instance = current.data('instance');
                    if( instance ){
                        var islist = instance.currentView && instance.currentView.collection;

                        if(!helpers.html_difference(component, instance.html, islist && !preserve)){
                            recursive(component);
                        }else{
                            if(instance.model){
                                console.log('app:updatecomponent->instance:', instance);
                                instance.model.set('el', component);
                                instance.render();
                            }else{
                                console.log('app:updatecomponent->parent-instance', instance, instance._parentView);
                                instance._parentView.changeComponentContent(name, component);
                            }
                        }
                    }
                }else if(haslist) {
                    var instance = list.data('instance');

                    if(instance.currentView){
                        instance.currentView.addComponentContent(component);
                    }
                }
            }
        },
        updateSessions: function(sessions, callback){
            var self = this;
            var rendered = self.body;

            for(var key in sessions){
                if(key != 'scripts' && key != 'styles' && key != 'content'){
                    self.updateSession(sessions[key], rendered);
                }
            }

            callback && callback();
        },
        updateSession: function(session, rendered){
            var self = this;

            self.updateComponent(session, rendered, function(component){
                var components = helpers.get_components(component);

                if(components){
                    components.each(function(){
                        self.updateSession(this, rendered);
                    });
                }
            });
        },
        scrollTo: function(target, speed, callback){
            try{
                if($(target).length > 0){
                    helpers.scrollTo(target, speed, callback);
                }
            }catch(e){
            }
        }
    });

export default new App();

