import $ from 'jquery';
import scope from '../scope';

var eventMap = {
    'menu': {
        ga: 'menu,click,%0%'
    },
    'menuMinhaConta': {
        ga: 'menu-minha-conta,click,%0%'
    }
};

var firstignore = true;
var duplicateFix = false;

function Metrics() {
    var self = this;
    var queues = { ga: [] };

    //Valide if is mobile
    var version = '';
    if (/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)) {
        version = 'mobile:'
    }

    function parseVars(str, vars) {
        if (vars) {
            vars = vars + '';
            vars = vars.split(',');

            for (var x = 0; x < vars.length; x++) {
                str = str.replace('%' + x + '%', vars[x]);
            }
        }

        return str;
    }


    function trackerImage(src, vars) {
        var image = new Image(1, 1);
        image.src = parseVars(src, vars);
        image.className = 'metric-image';

        $('body').append(image);
    }


    function varToMap(map, vars) {
        if (vars && map.key !== undefined && map.value) {
            vars = vars + '';
            vars = vars.split(',');

            if (vars[map.key]) {
                return map.value[vars[map.key]];
            }
        }

        return map;
    }

    function event(event, vars, e) {
        if (duplicateFix === event) return;

        duplicateFix = event;

        setTimeout(function(){
            duplicateFix = false;
        },10);

        var axel = Math.random() + "";
        var a = axel * 10000000000000;

        if(!eventMap[event]){
            console.warn(event + ', event map not exist');
            return;
        }

        if (window.gtag) {
            var gdata = parseVars(eventMap[event].ga, vars).split(',');

            gtag('event', gdata[1], {
                'event_category': gdata[0],
                'event_label': gdata[2]
            });
        } else {
            queues.ga.push(event);
            return;
        }
    }


    this.event = event;

    var interval = setInterval(function () {
        if (window.gtag) {
            for (var i = 0; i < queues.ga.length; i++) {
                event(queues.ga[i]);
            }
        }

        if (window.gtag) {
            clearTimeout(interval);
        }
    }, 100);

    $(document).on('click', '[data-metrics]', function (e) {
        var ev = $(e.currentTarget).attr('data-metrics');
        if (ev) {
            event(ev, $(e.currentTarget, e).attr('data-metrics-vars'), e);
        }
    });

    $(document).on('mouseenter', '[data-metrics-hover]', function (e) {
        var ev = $(e.currentTarget).data('metrics');
        if (ev) {
            event(ev, $(e.currentTarget).data('metrics-vars'), e);
        }
    });

    var sections = $([]);
    this.initSections = function () {
        sections = $('[data-metric-scroll]');
        stopScroll = false;
    };
    var stopScroll = false;
    this.stopScroll = function () {
        stopScroll = true;
    };
    this.resumeScroll = function () {
        self.startScroll(null);
    };
    this.startScroll = function (fragment) {
        stopScroll = false;
        var section = $((fragment || window.location.hash).replace('!', ""));
        var metric = section.data('metric-scroll');

        if (metric && !$(section).data('fired')) {
            event(metric, section.data('metric-vars'));
            $(section).data('fired', true);
        }

    };

    $(window).on('scroll', function (e) {
        return;

        var lastEl = null;
        var lastPos = null;
        if (!stopScroll) {
            for (var i = 0; i < sections.length; i++) {
                var el = $(sections[i]);
                if (el.css('display') !== 'none' && (el.offset().top) - $(window).scrollTop() < 0) {
                    lastEl = el;
                    lastPos = i;
                }
            }

            if (lastEl) {
                if (!$(sections[lastPos]).data('fired')) {
                    var metric = lastEl.data('metric-scroll');
                    $(sections[lastPos]).data('fired', true);
                    event(metric, lastEl.data('metric-vars'));
                }
            }
        }
    });

    $(window).scroll();

    function pageview(page) {
        return;
        
        // if (firstignore) {
        //     firstignore = false;
        //     return;
        // };

        if (window.gtag) {
            gtag('event', 'pageview', {
                path: page
            });
        }
    }

    pageview(window.location.href);

    this.pageview = pageview;

    window.metric = event;
}


export default new Metrics();
