import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

import 'jquery-mask-plugin';

var masks = {
    cpf: '000.000.000-00',
    cnpj: '00.000.000/0000-00',
};

export default {
    mask: {
        ui: {
            login: '.mask_login, [data-rule-login]'
        },
        listener: function(form) {
            $(document).on('keypress', this.ui.login, function (e) {
                try {
                    var types = ($(this).data('rule-login') || 'cpf,cnpj,email').split(',');

                    var useCpf = types.indexOf('cpf') !== -1;
                    var useCnpj = types.indexOf('cnpj') !== -1;
                    var useMask = useCpf || useCnpj;

                    if(!useMask) return;

                    var digit = e.key.replace(/\D/g, '');
                    var value = $(this).val();
                    var valueDocs = $(this).val().replace(/\D/g, '');
    
                    // se digitar letras, retira mask, caso contrário continua o processo
                    if ((event.keyCode >= 65 && event.keyCode <= 122) || value.match(/[a-z]/i)) {
                        $(this).unmask();
                    } else {
                        if(useCpf && !useCnpj) {
                            $(this).mask(masks.cpf);
                        }else if(!useCpf && useCnpj) {
                            $(this).mask(masks.cnpj);
                        }else{
                            var size = valueDocs.concat(digit).length;

                            $(this).mask(size <= 11 ? masks.cpf : masks.cnpj);
                        }
                    }
                } catch (er) {}
            });
    
            var ctrlDown = false,
                ctrlKey = 17,
                cmdKey = 91,
                vKey = 86;
    
            $(document)
                .on('keydown', this.ui.login, function (e) {
                    if (e.keyCode == ctrlKey || e.keyCode == cmdKey) {
                        ctrlDown = true;
                    }
                })
                .on('keyup', this.ui.login, function (e) {
                    if (e.keyCode == ctrlKey || e.keyCode == cmdKey) {
                        ctrlDown = false;
                    }
                });
    
            // Document Ctrl + C/V
            $(document).on('keydown', this.ui.login, function (e) {
                if (ctrlDown && e.keyCode == vKey) {
                    $(this).unmask();
                }
            });
    
            $(document).on('focusout blur', this.ui.login, function (e) {
                var types = ($(this).data('rule-login') || 'cpf,cnpj,email').split(',');

                var useCpf = types.indexOf('cpf') !== -1;
                var useCnpj = types.indexOf('cnpj') !== -1;
                var useMask = useCpf || useCnpj;

                if(!useMask) return;

                var valueDocs = $(this).val().replace(/\D/g, '');
                var value = $(this).val();
                var size = valueDocs.length;
    
                // se digitar letras, retira mask, caso contrário continua o processo
                if (value.match(/[a-z]/i)) {
                    $(this).unmask();
                } else {
                    if(useCpf && !useCnpj) {
                        $(this).mask(masks.cpf);
                    }else if(!useCpf && useCnpj) {
                        $(this).mask(masks.cnpj);
                    }else{
                        $(this).mask(size <= 11 ? masks.cpf : masks.cnpj);
                    }
                }
            });
        }
    }
};
