import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

var requireoneClear = false;

export default {
    validate: {
        methods: function(validator) {
            validator.addMethod(
                "requireone", 
                function(value, element, typeElement) {
                    if (requireoneClear) {
                        return true;
                    }
                console.log(typeElement);
                    var orElement = $('input, select').filter('[name="' + typeElement + '"]');
                
                    var orRequired = validator.methods.required.apply(this, [orElement.val(), orElement[0]]);
                    var selfRequired = validator.methods.required.apply(this, [value, element]);
                
                    if (orRequired || selfRequired) {
                        requireoneClear = true;
                        $(element).valid();
                        $(orElement).valid();
                        requireoneClear = false;
                
                        return true;
                    }
                
                    return false;
                }, 
                "Preencher pelo menos um dos campos"
            );
        }
    }
};
