import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

export default {
    validate: {
        methods: function(validator) {
            validator.addMethod(
                'completename',
                function (value, element) {
                    return this.optional(element) || /^.+\s.+$/i.test(value);
                },
                'Campo deve conter nome e sobrenome'
            );

            validator.addMethod(
                'date',
                function (value, element) {
                    return this.optional(element) || /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(value);
                },
                'Informe uma data válida'
            );

            validator.addMethod(
                'cnpj',
                function (value, element) {
                    var numeros, digitos, soma, i, resultado, pos, tamanho, digitos_iguais;

                    if (value.length == 0) {
                        return true;
                    }

                    value = value.replace(/\D+/g, '');
                    digitos_iguais = 1;

                    for (i = 0; i < value.length - 1; i++) {
                        if (value.charAt(i) != value.charAt(i + 1)) {
                            digitos_iguais = 0;
                            break;
                        }
                    }

                    if (digitos_iguais) {
                        return false;
                    }

                    tamanho = value.length - 2;
                    numeros = value.substring(0, tamanho);
                    digitos = value.substring(tamanho);
                    soma = 0;
                    pos = tamanho - 7;
                    for (i = tamanho; i >= 1; i--) {
                        soma += numeros.charAt(tamanho - i) * pos--;
                        if (pos < 2) {
                            pos = 9;
                        }
                    }
                    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
                    if (resultado != digitos.charAt(0)) {
                        return false;
                    }
                    tamanho = tamanho + 1;
                    numeros = value.substring(0, tamanho);
                    soma = 0;
                    pos = tamanho - 7;
                    for (i = tamanho; i >= 1; i--) {
                        soma += numeros.charAt(tamanho - i) * pos--;
                        if (pos < 2) {
                            pos = 9;
                        }
                    }

                    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

                    return resultado == digitos.charAt(1);
                },
                'Informe um CNPJ válido.'
            );

            validator.addMethod(
                'cpf',
                function (value, element) {
                    var cpf, expReg, a, b, c, i, x, y;

                    value = jQuery.trim(value);

                    value = value.replace('.', '');
                    value = value.replace('.', '');
                    cpf = value.replace('-', '');
                    while (cpf.length < 11) {
                        cpf = '0' + cpf;
                    }
                    expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
                    a = [];
                    b = Number();
                    c = 11;
                    for (i = 0; i < 11; i++) {
                        a[i] = cpf.charAt(i);
                        if (i < 9) {
                            b += a[i] * --c;
                        }
                    }
                    if ((x = b % 11) < 2) {
                        a[9] = 0;
                    } else {
                        a[9] = 11 - x;
                    }
                    b = 0;
                    c = 11;
                    for (y = 0; y < 10; y++) {
                        b += a[y] * c--;
                    }
                    if ((x = b % 11) < 2) {
                        a[10] = 0;
                    } else {
                        a[10] = 11 - x;
                    }
                    if (cpf.charAt(9) != a[9] || cpf.charAt(10) != a[10] || cpf.match(expReg)) {
                        return false;
                    }

                    return true;
                },
                'Informe um CPF válido.'
            );

            validator.addMethod(
                'cep',
                function (value, element) {
                    return this.optional(element) || /^[0-9]{5}-[0-9]{3}$/.test(value);
                },
                'Informe um CEP válido'
            );

            validator.addMethod(
                'phone',
                function (value, element) {
                    return this.optional(element) || /^\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}$/.test(value);
                },
                'Informe um numero válido'
            );

            validator.addMethod(
                'lettersonly',
                function (value, element) {
                    return this.optional(element) || /^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i.test(value);
                },
                'Campo deve conter apenas letras e espaços'
            );

            validator.addMethod(
                'information',
                function (value, element) {
                    return this.optional(element) || value.replace(/[0-9\s\n]+/gim, "").length > 5;
                },
                'Campo deve conter informações suficientes'
            );

            validator.methodGroup('fullname', [
                {
                    rule: 'lettersonly',
                },
                {
                    rule: 'completename',
                },
                {
                    rule: 'rangelength',
                    param: [5, 75],
                },
            ]);

            validator.methodGroup('message', [
                {
                    rule: 'information',
                },
                {
                    rule: 'rangelength',
                    param: [5, 9999],
                },
            ]);

            validator.methodGroup('min2digits', [
                { rule: 'minlength', param: [2] },              
            ]);

            validator.methodGroup('pin', [
                { rule: 'number' },              
                { rule: 'minlength', param: [6] },              
                { rule: 'maxlength', param: [6] },        
            ]);

            validator.addMethod(
                'zero-currency',
                function (value, element) {
                     return this.optional(element) || value !== '0,00'
                },
                'Valores precisam ser maiores que zero'
            );


            validator.addMethod(
                'afterdate', 
                function (value, el, minDate) {
                    if (this.optional(el)) {
                        return true;
                    }
                    var splitDate = value.split('/');
                    var splitCompareDate = minDate.split('/');

                    if(splitDate && splitDate.length < 3 || splitCompareDate && splitCompareDate.length < 3){
                        return false;
                    }

                    var selectedDate = new Date(splitDate[2], splitDate[1], splitDate[0]);
                    var compareDate = new Date(splitCompareDate[2], splitCompareDate[1], splitCompareDate[0]);

                    compareDate = new Date(compareDate.setHours(0));
                    compareDate = new Date(compareDate.setMinutes(0));
                    compareDate = new Date(compareDate.setSeconds(0));
                    compareDate = new Date(compareDate.setMilliseconds(0));
                
                    return compareDate <= selectedDate;
                }, 
                'Data deve ser maior que {0}.'
            );
            
            validator.addMethod('beforedate', function (value, el, maxDate) {
                if (this.optional(el)) {
                    return true;
                }
                var splitDate = value.split('/');
                var splitCompareDate = minDate.split('/');

                if(splitDate && splitDate.length < 3 || splitCompareDate && splitCompareDate.length < 3){
                    return false;
                }

                var selectedDate = new Date(splitDate[2], splitDate[1], splitDate[0]);
                var compareDate = new Date(splitCompareDate[2], splitCompareDate[1], splitCompareDate[0]);
            
                compareDate = new Date(compareDate.setHours(0));
                compareDate = new Date(compareDate.setMinutes(0));
                compareDate = new Date(compareDate.setSeconds(0));
                compareDate = new Date(compareDate.setMilliseconds(0));
            
                return selectedDate <= compareDate;
            }, 'Data deve ser menor que {0}.');

            validator.addMethod("requiregroup", function (value, element, options) {
                var $fields = $( options[ 1 ], element.form ),
                    $fieldsFirst = $fields.eq( 0 ),
                    validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this ),
                    isValid = $fields.filter( function() {
                        return validator.elementValue( this );
                    } ).length >= options[ 0 ];

                // Store the cloned validator for future validation
                $fieldsFirst.data( "valid_req_grp", validator );

                // If element isn't being validated, run each require_from_group field's validation rules
                if ( !$( element ).data( "being_validated" ) ) {
                    $fields.data( "being_validated", true );
                    $fields.each( function() {
                        validator.element( this );
                    } );
                    $fields.data( "being_validated", false );
                }
                return isValid;
            }, 'Preencha pelo menos um destes campos.');
        
        }
    }
};
