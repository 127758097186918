import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

export default {
    validate: {
        methods: function(validator) {
            validator.addMethod(
                'login',
                function (value, element) {
                    var types = ($(element).data('rule-login') || 'cpf,cnpj,email').split(',');

                    if(this.optional(element)) return true;

                    for(var x = 0; x < types.length; x++){
                        if(validator.methods[types[x]]){
                            if(validator.methods[types[x]].apply(this, arguments)) {
                                return true;
                            }
                        }
                    }

                    return false;
                },
                'Informe um login válido'
            );

            validator.addMethod(
                'user', 
                function (value, element) {
                    return this.optional(element) || !validator.methods.containSpecialChar.apply(this, arguments);
                }, 
                "Informe um usuário válido."
            );

            validator.addMethod(
                'containDigits', 
                function (value, element) {
                    return this.optional(element) || (/\d+/).test(value);
                }, 
                "Campo deve conter números."
            );
            
            validator.addMethod(
                'containLowerLetter', 
                function (value, element) {
                    return this.optional(element) || (/[a-z]+/).test(value);
                }, 
                "Campo deve conter caracteres minúsculos."
            );
            
            validator.addMethod(
                'containUpperLetter', 
                function (value, element) {
                    return this.optional(element) || (/[A-Z]+/).test(value);
                }, 
                "Campo deve conter caracteres maiúsculos."
            );
            
            validator.addMethod(
                'containSpecialChar', 
                function (value, element) {
                    return this.optional(element) || (/[!,@,#,$,%,^,&,*,?,_,~,\-,�,(,)]+/).test(value);
                }, 
                "Campo deve conter caracteres especiais."
            );
            
            validator.addMethod(
                'notSequentialChar', 
                function (value, element) {
                    var password = value.split('');
                    
                    var notSequencial = true;
                    
                    var count1L = 0;
                    var count2L = 0;
                
                    var count1N = 0;
                    var count2N = 0;
                    var count3N = 0;
                
                    var previous = null;
                    var IsLetter = /[a-zA-z,!,@,#,$,%,^,&,*,?,_,~,\-,�,(,)]+/;
                    var IsNumber = /\d+/;
                
                    /*eslint max-depth: ["error", 10]*/
                    for (var x = 0; x <= password.length; x++) {
                        var c = password[x];
                
                        if (previous) {
                            if (IsLetter.test(c)) {
                                count1N = 0;
                                count2N = 0;
                                count3N = 0;
                
                                if (IsLetter.test(previous)) {
                                    if (c == (previous) + 1) {
                                        count1L++;
                
                                        if (count1L >= 2) {
                                            notSequencial = false;
                                            break;
                                        }
                                    } else {
                                        count1L = 0;
                                    }
                
                                    if (c == (previous)) {
                                        count2L++;
                
                                        if (count2L >= 2) {
                                            notSequencial = false;
                                            break;
                                        }
                                    } else {
                                        count2L = 0;
                                    }
                                } else {
                                    count1L = 0;
                                    count2L = 0;
                                }
                            } else if (IsNumber.test(c)) {
                                count1L = 0;
                                count2L = 0;
                
                                if (IsNumber.test(previous)) {
                                    var n1 = parseInt(c);
                                    var n2 = parseInt(previous);
                
                                    if (n1 == n2 + 1) {
                                        count1N++;
                
                                        if (count1N >= 2) {
                                            notSequencial = false;
                                            break;
                                        }
                                    } else {
                                        count1N = 0;
                                    }
                
                                    if (n1 == n2 - 1) {
                                        count2N++;
                
                                        if (count2N >= 2) {
                                            notSequencial = false;
                                            break;
                                        }
                                    } else {
                                        count2N = 0;
                                    }
                
                                    if (n1 == n2) {
                                        count3N++;
                
                                        if (count3N >= 2) {
                                            notSequencial = false;
                                            break;
                                        }
                                    } else {
                                        count3N = 0;
                                    }
                                } else {
                                    count1N = 0;
                                    count2N = 0;
                                    count3N = 0;
                                }
                            }
                        }
                
                        previous = c;
                    }
                
                    return this.optional(element) || notSequencial;
                }, 
                "Campo não pode conter caracteres em sequência (Ex. 123, abc)."
            );

            validator.methodGroup('simplepassword', [
                { rule: 'minlength', param: [8] },
            ]);
            
            validator.methodGroup('strongpassword', [
                { rule: 'minlength', param: [8] },
                { rule: 'containDigits' },
                { rule: 'containLowerLetter' },
                { rule: 'containUpperLetter' },
                { rule: 'containSpecialChar' },
                { rule: 'notSequentialChar' }
            ]);
        }
    }
};
