import { Behavior } from 'backbone.marionette';
import $ from 'jquery';

var SelectPopulate = Behavior.extend({
    ui: {
        selectPopulate: '[data-select-populate]',
    },
    events: {
        'change @ui.selectPopulate': 'selectPopulateListener',
    },
    onRender: function () {
        this.onAttach();
    },
    onViewAttach: function(){
        this.onAttach();
    },
    onAttach: function () {
        // this.ui.selectPopulate.trigger('change');
    },
    selectPopulateListener: function(e) {
        var self = this,
            $selectElm = $(e.target),
            $selectValue = $selectElm.val(),
            $selectTarget = $($selectElm.data('select-populate-target'));

        if(!$selectValue) return;

        $.ajax({
            url: $selectElm.data('select-populate-url').replace('%id%', $selectValue),
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                $selectTarget.html('<option value=""></option>');
                var $elms = $.each(data, function (idx, elm) {
                    console.log(arguments);
                    if(elm && elm.id){
                        idx = elm.id;
                        elm = elm.name || elm.text;
                    }
                    
                    var opt = new Option(elm, idx, false, false);
                    
                    $(opt).appendTo($selectTarget);
                });

                $selectTarget.removeAttr('disabled');
                $selectTarget.trigger('change');

                $selectTarget.trigger('populate', [data]);
            },
        });
    },
});


export default SelectPopulate;