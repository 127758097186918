import $ from 'jquery';
import Mn from 'backbone.marionette';
import scope from '../scope';

var Behavior = Mn.Behavior.extend({
    ui: {
        like: 'a[data-like]'
    },
    events: {
        'click @ui.like': 'doLike'
    },
    doLike: function(e){
        e.preventDefault();

        var target = $(e.currentTarget);
        var url = target.attr('href');

        this.setLike(url, !target.hasClass('like--on'), function(){
            console.log('like:set', target);
            target.toggleClass('like--on');
        });
    },
    setLike: function(url, status, callback){
        var self = this;
        var current = window.location.href;
        scope.app.layout.waiting(true, 1000);

        scope.login_title = "Entre para favoritar.";
        scope.login_callback = function(){
            self.setLike(url, function(){
                callback && callback();
                scope.app.navigate(current, false, false, true);
            });
        };

        $.ajax({
            url: url,
            data: {
                status: status
            },
            method: 'GET',
            dataType: 'json',
            complete: function(resp){
                if(resp.responseJSON && resp.responseJSON.success){
                    scope.app.layout.waiting(false);
                    scope.login_callback = null;
                    callback && callback(resp);
                }
            }
        });
    }
});

export default Behavior;