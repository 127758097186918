import $ from 'jquery';
import Component from '../../libs/component';
import swal from '../../libs/swal';
import scope from '../../scope';

scope.components['header'] = Component.extend({
    name: 'header',
    ui: {
        'burguer': '.header__burguer',
        'login': '.header__login',
        'profile': '.header__profile',
        'menu': '.header__menu',
        'menuProfile': '.header__menu-profile',
        'links': '.header__menu-link',
        'linksProfile': '.header__menu-profile-link',
        'linksLogout': '.header__menu-profile-link--logout',
        'logo': '.header__logo a'
    },
    events: {
        'click @ui.burguer': 'toggleMenu',
        'click @ui.profile': 'toggleProfile',
        'click @ui.login': 'closeAllMenu',
        'click @ui.logo': 'closeAllMenuAndModal',
        'click @ui.links': 'closeAllMenuAndModal',
        'click @ui.linksProfile': 'closeAllMenuAndModal',
        'click @ui.linksLogout': 'gsiLogout',
    },
    onAttach: function(e, data){
        var self = this;
        var headerHeight = self.$el.innerHeight();

        $(document).off("scroll.header").on("scroll.header", function (e) {
            var scrollPos = $(document).scrollTop();

            if (scrollPos > headerHeight) {
                self.$el.parent().addClass("fixed");
            } else {
                self.$el.parent().removeClass("fixed");
            }
        }).off("mousedown.header").on("mousedown.header", function (e) {
            var target = $(e.target);

            if(!target.closest(".header").length){
                self.closeAllMenu();
            }
        });
    },
    toggleMenu: function(e){
        e.preventDefault();

        this.closeProfile();
        $('body').toggleClass('menu-open');
    },
    toggleProfile: function(e){
        e.preventDefault();

        this.closeMenu();
        $('body').toggleClass('menu-profile-open');
    },
    closeMenu: function(){
        $('body').removeClass('menu-open');
    },
    closeProfile: function(){
        $('body').removeClass('menu-profile-open');
    },
    closeAllMenu: function(){
        this.closeProfile();
        this.closeMenu();
    },
    closeAllMenuAndModal: function() {
        this.ui.links.removeClass('active');
        this.closeAllMenu();
        swal.close();
    },
    gsiLogout: function(){
        try{
            scope.gsi(function(id){
                id.disableAutoSelect();
            });
        }catch(e){
            window.location = '/';
        }
    }
});

export default scope.components['header'];
