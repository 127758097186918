import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

import 'jquery-mask-plugin';

var masks = {
    cep: '00000-000',
    date: '00/00/0000',
    cpf: '000.000.000-00',
    cnpj: '00.000.000/0000-00',
    phone: '(00) 90000-0000',
    pin: '000000',
};

// Mascara para Telefone padrão 8 e 9 dígitos
// Fonte: https://github.com/igorescobar/jQuery-Mask-Plugin/issues/527
// Conforme exemplo do criador: http://igorescobar.github.io/jQuery-Mask-Plugin/js/examples.js
var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
        onKeyPress: function (val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        },
    };


export default {
    mask: {
        ui: {
            date: '.mask_date, [data-rule-date], [data-rule-datepicker]',
            cpf: '.mask_cpf, [data-rule-cpf]',
            cnpj: '.mask_cnpj, [data-rule-cnpj]',
            cep: '.mask_cep, [data-rule-cep]',
            phone: '.mask_phone, [data-rule-phone]',
            pin: '.mask_pin, [data-rule-pin]',
        },
        listener: function(form) {
            //simple
            $(this.ui.date, form).mask(masks.date);
            $(this.ui.cpf, form).mask(masks.cpf);
            $(this.ui.cnpj, form).mask(masks.cnpj);
            $(this.ui.cep, form).mask(masks.cep);
            $(this.ui.pin, form).mask(masks.pin);
            $(this.ui.phone, form).mask(SPMaskBehavior, spOptions);

            $(Object.values(this.ui).join(',')).data('maskClearRegex', /[^0-9]/g);
        }
    }
};
