import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

import '@chenfengyuan/datepicker';
import '@chenfengyuan/datepicker/i18n/datepicker.pt-BR';

export default {
    validate: {
        methods: function (validator) {
            validator.methodGroup('datepicker', [
                {
                    rule: 'date',
                },
            ]);
        },
        listener: function (form) {
            $('input[data-rule-datepicker]:not([readonly])', form).each(function () {
                $(this).attr('type', 'text');
                $(this).datepicker(
                    _.extend(
                        {
                            language: 'pt-BR',
                        },
                        $(this).data('rule-datepicker'),
                    ),
                );
            });
        },
    },
};
