import $ from 'jquery';
import _ from 'underscore';
import Mn from 'backbone.marionette';
import Backbone from 'backbone';
import swal from './swal';
import scope from '../scope';
import Urijs from 'urijs';
import metrics from './metrics';

var Router = Mn.Object.extend({
    initialize: function (app) {
        var self = this;
        this.base = $('#base-id').attr('href') || window.location.href;
        this.base = this.base.search('http') != -1 ? this.base : window.location.protocol + this.base;
        this.root = Urijs(this.base);
        this.app = app;

        var realXHR;
        var forcedLoading;
        var _orgAjax = $.ajaxSettings.xhr;

        $.ajaxSettings.xhr = function () {
            realXHR = _orgAjax();
            return realXHR;
        };

        $.xhrPool = [];
        $.xhrPool.abortAll = function() {
            $(this).each(function(i, xhr) {
                xhr.userAbort = true;
                xhr.abort();
                $.xhrPool.splice(i, 1);
            });
        }
        $.ajaxSetup({
            beforeSend: function(xhr, options){
                $.xhrPool.push(xhr);

                clearTimeout(forcedLoading);

                forcedLoading = setTimeout(function(){
                    // scope.app.layout.waiting(true);
                }, 6000);

                xhr.url = options.url;
            },
            complete: function(xhr){
                var i = $.xhrPool.indexOf(xhr);
                if (i > -1) $.xhrPool.splice(i, 1);
            }
        });

        var redirect = function (event, xhr, options) {
            clearTimeout(forcedLoading);

            console.log('ajax:response', xhr, options);

            if(xhr.status === 401 || xhr.status === 403 || xhr.status === 412){
                scope.app.layout.waiting(false);

                var to = scope.app.base + (xhr.status === 412 ? '/manager/warning' : (xhr.status === 403 ? '/manager/pin' : '/manager/login'));

                if(options.url.search('/divulgue/anuncie') != -1){
                    scope.login_title = "Entre para publicar seu anuncio.";
                    scope.login_redirect = scope.app.base + '/divulgue/anuncie';
                }
                
                if(!options || !options.url || !(options.url.search('like') != -1 || options.url.search('rating') != -1 || options.url.search('rate') != -1 || options.url.search('notify') != -1)){
                    self.navigate(self.previous || to, true, undefined, undefined, true);
                }
                
                
                scope.app.modal(scope.app.base + (xhr.status === 412 ? '/warning' : (xhr.status === 403 ? '/pin' : '/login')));
                return;
            }

            if(xhr.userAbort || scope.login_callback || scope.login_redirect) return;
                
            if (xhr.responseJSON && (xhr.responseJSON.redirect || xhr.responseJSON.redirectUrl)) {
                var redirect = xhr.responseJSON.redirect || xhr.responseJSON.redirectUrl;
                
                xhr.redirected = true;

                if (window.location == redirect || window.location == redirect + '/') {
                    console.log('ajax:response:location:reload', redirect);
                    return window.location.reload();
                } else if(redirect.search('manager') != -1){
                    console.log('ajax:response:location:redirect', redirect);
                    return window.location.href = redirect;
                } else if(redirect.search('like') != -1 || redirect.search('rating') != -1 || redirect.search('notify') != -1) {
                    return;
                }

                console.log('ajax:response:ajax:redirect', redirect);
                scope.app.router.navigate(redirect, false, false, true);
            }else if(!xhr.responseJSON && xhr.responseText && !(realXHR.responseURL == options.url || realXHR.responseURL == options.url + '/')){
                xhr.redirected = true;
                xhr.server_redirected = true;

                console.log('ajax:server:redirect', realXHR.responseURL);
                scope.app.router.navigate(realXHR.responseURL, false, false, true);
            }
        };

        $(document).ajaxSuccess(redirect).ajaxError(redirect);
        
        this.init();
    },
    back: function () {
        if (this.previous) {
            this.navigate(this.previous);
            return true;
        }

        return false;
    },
    init: function () {
        var self = this;
        var route = this.real = new Backbone.Router();
        
        Backbone.history.start({
            pushState: true,
            root: self.root.pathname()
        });

        route.route(/.*/, function () {
            console.log('location:', location);
            self.page(location.href);
        });

        $('body').on('click', 'a:not(a[data-bypass],a[data-back],a[data-fbshare],a[data-external],a[rel="modal:close"],a[href="#"],a[data-tabs],a[data-collapse])',function (event) {
            var link = event.currentTarget;
            var href = link.href;
            var anchor = null;
            
            // Only anchors tags
            if (link.tagName.toUpperCase() !== 'A')
                return;

            if (link.target === '_blank' || href.search('/manager') != -1 || window.location.href.search('/manager') != -1) {
                return;
            }

            if($(link).is('[rel="modal:open"]')){
                event.preventDefault();
                
                scope.login_redirect = $(link).data('redirect') || false;

                self.modal(href, $(link).data('modal-options'));
                return;
            }

            // Middle click, cmd click, and ctrl click should open
            // links in a new tab as normal.
            if ( event.which > 1 || event.metaKey || event.ctrlKey || event.shiftKey || event.altKey )
                return;

            // Ignore cross origin links
            if ( location.protocol !== link.protocol || location.hostname !== link.hostname )
                return;

            // Ignore case when a hash is being tacked on the current URL
            if ( href.indexOf('#') > -1){
                
                var full = self.root.toString();
                var parser = href.match(/(\#[^?]*)/);
                
                if(/^#/.test(parser[0])){
                    if(href.replace(full,  '').replace('//', '/').replace('/#', '#').replace(/#.*/, '') === location.href.replace(full,  '').replace('//', '/').replace('/#', '#').replace(/#.*/, '')){
                        event.preventDefault();
                        scope.app.scrollTo(parser[0])
                    }else{
                        href = href.replace(/#.*/, '');
                        anchor = parser[0];
                        console.log('anchor:', anchor);
                    }
                }
            }

            // Ignore event with default prevented
            if (event.isDefaultPrevented())
                return;

            $.xhrPool.abortAll();

            event.preventDefault();
            self.navigate(href, undefined, anchor);
        });

        scope.app.navigate = function(url, replace, anchor, forceLoadSamePage){
            self.navigate(url, replace, anchor, forceLoadSamePage);
        }

        scope.app.modal = function(url, options){
            self.modal(url, options);
        }
    },
    navigate: function (url, replace, anchor, forceLoadSamePage, dontTrigger) {
        this.previous = location.href;
        this.anchor = anchor;
        var full = this.root.toString(), navigate = (url + '/' === full) ? '' : url.replace(full,  '').replace('pushstate=1', '');
        var result = Backbone.history.navigate(navigate + (anchor || ''), {trigger: !dontTrigger, replace: replace});

        if(result === undefined && forceLoadSamePage){
            return Backbone.history.loadUrl(navigate + (anchor || ''));
        };

        return result;
    },
    page: function (url) {
        var self = this;
        var anchor = self.anchor;
        console.log('router:page:request', url, anchor);
        scope.app.layout.waiting(true);
        
        if(!scope.app.cache(url)){
            $.ajax({
                anchor: anchor,
                url: url,
                dataType: 'json',
                data: {
                    pushstate: true
                },
                success: function(response){
                    if(!response) response = {};

                    response.scroll = response.scroll ? response.scroll : anchor;

                    console.log('router:page:response', url, anchor, response);
                    scope.app.setup(url, response, function(){
                        console.log('router:page:setup:complete', url, anchor, response);
                        scope.app.layout.waiting(false);

                        metrics.pageview(url);
                    });
                }
            });
        }else{
            console.log('router:page:cache', url);
            setTimeout(function(){
                scope.app.layout.waiting(false);
            }, 500);
        }

        self.anchor = null;
    },
    cacheModal: {},
    modal: function(url, options) {
        console.log('router:modal:request', url);
        var self = this;

        // if(self.cacheModal[url] && self.cacheModal[url].instance) {
        //     console.log('router:modal:cache', url);
        //     self.modalSwal(self.cacheModal[url].instance);
        //     return;
        // }
        
        swal.showLoading();
        swal.update({
            allowOutsideClick: false
        });

        if ( url.indexOf('#') > -1){
            var split = url.match(/\#(.*)/gim);

            if(split && split[0]){
                var target = $(split[0]);
                swal.fire({
                    title: target.data('title'),
                    icon: target.data('icon'),
                    html: target.children(),
                    showConfirmButton: false
                });
            }

            return;
        }

        $.ajax({
            url: url + '?pushstate=true',
            dataType: 'json',
            success: function(response){
                console.log('router:modal:response', url, response);

                if(!response || !response.success || !response.sections || !response.sections.content) {
                    swal.hideLoading();

                    swal.fire('Desculpe', 'Não foi possivel concluir esta solicitação, tente novamente mais tarde.', "error");
                    return;
                };
                
                scope.app.updateScripts(response.sections && response.sections.scripts, function(){
                    scope.app.updateStyles(response.sections && response.sections.styles, function(){
                        scope.app.updateModal(response.sections && response.sections.content, function(instance){
                            if(!instance) {
                                swal.hideLoading();
                                swal.fire('Ops', 'Houve um erro erro inesperado, por favor tente novamente mais tarde.', "error");
                                return;
                            }

                            instance.load().done(function (response) {
                                console.log('router:modal:setup:complete', url, response);

                                swal.hideLoading();
                                instance._modal = swal;
                                instance.populate();

                                self.cacheModal[url] = instance._modalCache = {
                                    url: url,
                                    options: options,
                                    instance: instance, 
                                };
                                
                                self.modalSwal(instance, options);
                            });
                        })
                    })
                });
            }
        })
    },
    modalSwal: function(instance, options){
        console.log('router:swal:open', instance, options);

        scope.modal = swal.fire(_.extend({
            html: instance.el,
            instance: instance,
            showConfirmButton: false,
            didDestroy: function() {
                setTimeout(function(){
                    instance.$el.find('form').trigger('reset');
                }, 1000);
            }
        }, options));
    }
});

export default Router;
