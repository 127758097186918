import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

var facebookRegex = /(www\.)?facebook.com(\.[A-z]+)?\/(.+)$/;
var instagramRegex = /(www\.)?instagram.com(\.[A-z]+)?\/(.+)$/;
var twitterRegex = /(www\.)?twitter.com(\.[A-z]+)?\/(.+)$/;
var youtubeRegex = /(www\.)?youtube.com(\.[A-z]+)?\/(.+)$/;
var linkedinRegex = /(www\.)?linkedin.com(\.[A-z]+)?\/(.+)$/;
var userReferenceRegex = /@([A-z0-9\-\_]+)/;

export default {
    validate: {
        methods: function (validator) {
            validator.addMethod(
                'url2',
                function (value, element) {
                    if(value && value.search('http') === -1){
                        value = 'https://' + value;
                    }

                    return this.optional(element) || validator.methods.url.apply(this, [value, element]);
                },
                'Url inválida',
            );

            validator.addMethod(
                'facebook-url',
                function (value, element) {
                    return this.optional(element) || facebookRegex.test(value);
                },
                'Url do facebook inválida',
            );

            validator.addMethod(
                'instagram-url',
                function (value, element) {
                    return this.optional(element) || instagramRegex.test(value);
                },
                'Url do instagram inválida',
            );

            validator.addMethod(
                'user-reference',
                function (value, element) {
                    return this.optional(element) || userReferenceRegex.test(value);
                },
                'Usuário precisa iniciar com @',
            );

            validator.addMethod(
                'twitter-url',
                function (value, element) {
                    return this.optional(element) || twitterRegex.test(value);
                },
                'Url do twitter inválida',
            );

            validator.addMethod(
                'youtube-url',
                function (value, element) {
                    return this.optional(element) || youtubeRegex.test(value);
                },
                'Url do youtube inválida',
            );

            validator.addMethod(
                'linkedin-url',
                function (value, element) {
                    return this.optional(element) || linkedinRegex.test(value);
                },
                'Url do linkedin inválida',
            );
        },
    },
};