import { Behavior } from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';

export default {
    validate: {
        methods: function(validator) {
            validator.addMethod(
                "requireif", 
                function(value, element, param ) {
                    var self = this;
                    var parse = param.split(',');
                    var el = parse[0];
                    var contain = parse[1];

                    return validator.methods.required.apply(this, [
                        value, element, function() {
                            var target = $(el)[0];
                            var result = self.elementValue(target);
                console.log('resultou', result);
                            return contain ? contain.search(result) != -1 : result;
                        }
                    ]);
                
                }, 
                "Campo obrigatório"
            );
        }
    }
};
