
import mask from './Masks/Medias';
import validate from './Validate/Medias';

export default {
    mask: mask.mask,
    validate: {
        cleanOnSerialize: Object.values(mask.mask.ui), 
        ...validate.validate,
    }
};
